<template>
  <div>
    <orders-list />
  </div>
</template>
<script>
import OrdersList from '@/layouts/components/orders/OrdersList.vue'

export default {
  components: {
    OrdersList,
  },
}
</script>
