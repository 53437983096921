<template>
  <div>
    <div class="my-2 d-flex justify-content-end">
      <button
        class="btn btn-primary"
        @click="handleAddAddress"
      >
        Add New Address
      </button>
    </div>
    <b-table
      ref="refCustomerAddressesTable"
      :items="fetchCustomerAddresses"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative cursor-pointer"
      @row-clicked="handleRowClick"
    >

      <template #cell(id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(name)="data">
        {{ data.item.name }}
      </template>

      <template #cell(area)="data">
        <span class="text-nowrap">
          {{ data.item.area.name }} / {{ data.item.area.city.name }}
        </span>
      </template>

      <template #cell(phone)="data">
        <span class="text-nowrap">
          {{ data.item.phone }}
        </span>
      </template>

      <template #cell(street)="data">
        <span class="text-nowrap">
          {{ data.item.street }}
        </span>
      </template>

      <template #cell(block)="data">
        <span class="text-nowrap">
          {{ data.item.block }}
        </span>
      </template>

      <template #cell(building)="data">
        <span class="text-nowrap">
          {{ data.item.building }}
        </span>
      </template>

      <template #cell(floor_number)="data">
        <span class="text-nowrap">
          {{ data.item.floor_number }}
        </span>
      </template>

      <template #cell(apartment_number)="data">
        <span class="text-nowrap">
          {{ data.item.apartment_number }}
        </span>
      </template>

      <template #cell(landmark)="data">
        <span class="text-nowrap">
          {{ data.item.landmark }}
        </span>
      </template>

      <template #cell(avenue)="data">
        <span class="text-nowrap">
          {{ data.item.avenue }}
        </span>
      </template>

      <template #cell(created_at)="data">
        <span>
          {{ data.item.created_at }}
        </span>
      </template>

    </b-table>

    <right-sidebar
      v-if="Object.keys(selectedAddress).length > 0"
      id="address-form"
      title="Edit Address"
      :hidden="() => selectedAddress = {}"
    >
      <address-form
        v-if="Object.keys(selectedAddress).length > 0"
        :customer-id="customerId"
        :address="selectedAddress"
        type="edit"
        @refreshAddressesList="refreshAddressesList"
      />
    </right-sidebar>

    <right-sidebar
      id="address-add-form"
      title="Add Address"
    >
      <address-form
        :customer-id="customerId"
        type="create"
        @refreshAddressesList="refreshAddressesList"
      />
    </right-sidebar>

  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BTable,
  VBToggle,
} from 'bootstrap-vue'
import AddressForm from '@/layouts/components/customers/AddressForm.vue'
import RightSidebar from '../../sidebar/RightSidebar.vue'
import useCustomerAddresses from './useCustomerAddresses'

export default {
  components: {
    AddressForm,
    BTable,
    RightSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const selectedAddress = ref({})
    const {
      fetchCustomerAddresses,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerAddressesTable,
      id,

      refetchData,
    } = useCustomerAddresses()

    onMounted(() => {
      id.value = props.customerId
    })

    const handleRowClick = record => {
      selectedAddress.value = record
      setTimeout(() => {
        context.root.$emit('bv::toggle::collapse', 'address-form')
      }, 50)
    }

    const handleAddAddress = () => {
      context.root.$emit('bv::toggle::collapse', 'address-add-form')
    }

    const refreshAddressesList = () => {
      refetchData()
    }

    return {
      fetchCustomerAddresses,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerAddressesTable,
      handleRowClick,
      selectedAddress,
      handleAddAddress,
      refreshAddressesList,

      refetchData,
    }
  },
}
</script>
