var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container my-2"},[_c('validation-observer',{ref:"customerRules"},[_c('b-form',[_c('h3',[_vm._v("Basic Info")]),_c('b-row',{staticClass:"container"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"first-name"}},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","placeholder":"First Name","state":errors.length > 0 ? false:null},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"last-name"}},[_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","placeholder":"Last Name","state":errors.length > 0 ? false:null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('b-form-input',{attrs:{"id":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"phone"}},[_vm._v(" Phone Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"phone","placeholder":"Phone Number","state":errors.length > 0 ? false:null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"gender"}},[_vm._v(" Gender ")]),_c('b-input-group',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"form-control",attrs:{"id":"gender"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gender=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(" Male ")]),_c('option',{attrs:{"value":"2"}},[_vm._v(" Female ")])])])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"max-debt"}},[_vm._v(" Maximum Debt ")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"max-debt","placeholder":"Maximum Debt"},model:{value:(_vm.maxDebt),callback:function ($$v) {_vm.maxDebt=$$v},expression:"maxDebt"}})],1)],1)],1)],1),_c('hr'),_c('h3',[_vm._v("Special Discount")]),_c('b-row',{staticClass:"container"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"discount"}},[_vm._v(" Discount ")]),_c('validation-provider',{attrs:{"name":"Discount","rules":"between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"discount","placeholder":"Enter Percentage","state":errors.length > 0 ? false:null},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 w-100 my-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmitCustomer($event)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }