<template>
  <div>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            :variant="`flat-primary ${orderType == 1 ? 'active' : ''}`"
            @click="handleOrderTypeSelect(1)"
          >
            Active
          </b-button>
          <b-button
            :variant="`flat-primary mx-1 ${orderType == 0 ? 'active' : ''}`"
            @click="handleOrderTypeSelect(0)"
          >
            History
          </b-button>
        </b-col>
      </b-row>

    </div>
    <div class="card shadow-none border p-1 mb-0">
      <h3>{{ orderType == 1 ? 'All Active Orders' : 'History Orders' }}</h3>
    </div>
    <b-table
      ref="refCustomerOrdersTable"
      :items="fetchCustomerOrders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :current-page="currentPage"
      :per-page="perPage"
      tbody-tr-class="cursor-pointer"
      @row-clicked="handleRowClick"
    >

      <template #cell(id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(invoice_id)="data">
        #{{ data.item.invoice_id }}
      </template>

      <template #cell(type)="data">
        <!-- <img
          :src="require(`@/assets/images/icons/${data.item.type.icon}`)"
          width="20"
        > -->
        <span class="text-nowrap">
          {{ data.item.type }}
        </span>
      </template>

      <template #cell(created_at)="data">
        <span>
          {{ data.item.created_at }}
        </span>
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status"
          pill
          :style="`background-color: ${data.item.status.color ? data.item.status.color : '#82868b'}`"
        >
          <!-- :variant="`light-${data.item.status.color}`" -->
          <small>{{ data.item.status.name }}</small>
        </b-badge>
      </template>

      <template #cell(paid)="data">
        <div class="d-flex align-items-center">
          <span :class="`bullet bullet-sm bullet-${data.item.paid ? 'success' : 'danger'} mx-25`" />
          <span class="text-nowrap">
            {{ data.item.paid ? 'Yes' : 'No' }}
          </span>
        </div>
      </template>

      <template #cell(total)="data">
        <span>
          {{ (data.item.grand_total).toFixed(2) }}
        </span>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="12"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          sm="6"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCustomerOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <order-details
      v-if="selectedOrder"
      :order="selectedOrder"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BButton,
  BTable,
  VBToggle,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCustomerOrders from './useCustomerOrders'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    OrderDetails: () => import('../../orders/OrderDetails.vue'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const selectedOrder = ref(null)
    const {
      fetchCustomerOrders,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerOrdersTable,
      id,
      orderType,

      refetchData,
    } = useCustomerOrders()

    onMounted(() => {
      id.value = props.customerId
    })

    const handleOrderTypeSelect = type => {
      orderType.value = type
    }

    const handleRowClick = (record, index) => {
      selectedOrder.value = record.id
      setTimeout(() => {
        context.root.$emit('bv::show::modal', 'order-details')
      }, 50)
    }

    return {
      fetchCustomerOrders,
      orderType,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerOrdersTable,
      selectedOrder,
      handleRowClick,

      refetchData,
      handleOrderTypeSelect,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
