<template>
  <div>
    <div class="m-2">
      <b-card
        class="shadow-none bg-transparent border-secondary"
      >
        <h5 class="font-weight-bolder mb-2">
          Handling Preferences
        </h5>
        <b-row>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="handlingCallMe"
              :value="1"
              :unchecked-value="0"
            >
              Call me
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="handlingUseDoorBill"
              :value="1"
              :unchecked-value="0"
            >
              Use door bill
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="laundryBagNextToTheDoor"
              :value="1"
              :unchecked-value="0"
            >
              Laundry bag next to the door
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <div class="d-flex flex-column justify-content-between align-items-start">
              <b-form-checkbox
                v-model="handlingContactAnotherNumber"
                :value="1"
                :unchecked-value="0"
              >
                Contact another number
              </b-form-checkbox>
              <b-form-input
                v-if="handlingContactAnotherNumber"
                v-model="handlingPhone"
                type="text"
                placeholder="Phone Number"
                class="w-50 mt-1"
                @keypress="numbersOnly"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            v-if="editDefaultPickupDateTime"
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <label>Default Pickup Day</label>
            <v-select
              v-model="defaultPickupDay"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pickupDays"
              label="name"
              :clearable="true"
              class="preferences-select"
              placeholder="Select Default Pickup Day"
              :reduce="d => d.id"
              @input="handlePickupDayChange"
            />
          </b-col>
          <b-col
            v-if="editDefaultPickupDateTime"
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <label>Default Pickup Time</label>
            <v-select
              v-model="defaultPickupTime"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pickupHours"
              :clearable="true"
              class="preferences-select"
              placeholder="Select Default Pickup Day"
              :reduce="option => option.id"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                {{ option.start_at }} - {{ option.end_at }}
              </template>
              <template
                slot="selected-option"
                slot-scope="option"
              >
                {{ option.start_at }} - {{ option.end_at }}
              </template>
            </v-select>
          </b-col>
          <b-col
            v-if="editDefaultPickupDateTime"
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <button
              class="btn btn-danger btn-sm"
              @click="editDefaultPickupDateTime = false"
            >
              Cancel Edit Default Pickup Date And Time
            </button>
          </b-col>
          <b-col
            v-if="defaultPickupDayText && defaultPickupTimeText && !editDefaultPickupDateTime"
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <label>Last Saved Default Pickup Date And Time</label>
            <h6>{{ defaultPickupDayText + ' ' + defaultPickupTimeText }}</h6>
            <button
              class="btn btn-primary"
              @click="editDefaultPickupDateTime = true"
            >
              Edit
            </button>
          </b-col>
          <b-col
            v-if="!defaultPickupDayText && !defaultPickupTimeText && !editDefaultPickupDateTime"
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <button
              class="btn btn-primary btn-sm"
              @click="editDefaultPickupDateTime = true"
            >
              Add Default Pickup Date And Time
            </button>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        class="shadow-none bg-transparent border-secondary my-2"
      >
        <h5 class="font-weight-bolder mb-2">
          Delivery Preferences
        </h5>
        <b-row>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="deliveryCallMe"
              :value="1"
              :unchecked-value="0"
            >
              Call me
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="deliveryUseDoorBill"
              :value="1"
              :unchecked-value="0"
            >
              Use door bill
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="hangerItBehindTheDoor"
              :value="1"
              :unchecked-value="0"
            >
              Hanger it behind the door
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <div class="d-flex flex-column justify-content-between align-items-start">
              <b-form-checkbox
                v-model="deliveryContactAnotherNumber"
                :value="1"
                :unchecked-value="0"
              >
                Contact another number
              </b-form-checkbox>
              <b-form-input
                v-if="deliveryContactAnotherNumber"
                v-model="deliveryPhone"
                type="text"
                placeholder="Phone Number"
                class="w-50 mt-1"
                @keypress="numbersOnly"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        class="shadow-none bg-transparent border-secondary my-2"
      >
        <h5 class="font-weight-bolder mb-2">
          Service Preferences
        </h5>
        <b-row>
          <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            class=""
          >
            <b-form-checkbox
              v-model="perfuming"
              :value="1"
              :unchecked-value="0"
            >
              Perfuming
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            class="mt-1"
          >
            <h6>Ironing Type</h6>
            <div class="d-flex flex-column justify-content-between align-items-start">
              <b-form-radio
                v-for="type in ironingTypes"
                :key="type.id"
                v-model="ironingType"
                name="ironing-type"
                :value="type.id"
              >
                {{ type.name }}
              </b-form-radio>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            class="mt-1"
          >
            <h6>Starch Level</h6>
            <div class="d-flex flex-column justify-content-between align-items-start">
              <b-form-radio
                v-for="level in starchLevels"
                :key="level.id"
                v-model="starchLevel"
                name="starch-level"
                :value="level.id"
              >
                {{ level.name }}
              </b-form-radio>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            class="mt-1"
          >
            <h6>Folding And Hanging</h6>
            <div class="d-flex flex-column justify-content-between align-items-start">
              <b-form-radio
                v-for="type in foldingHangingTypes"
                :key="type.id"
                v-model="foldingHanging"
                name="folding-hanging"
                :value="type.id"
              >
                {{ type.name }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <div class="text-right">
        <button
          class="btn btn-success mt-1"
          :disabled="loading"
          @click="handleChangePreferences"
        >
          {{ loading ? 'Loading...' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadio,
    BFormInput,
    vSelect,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const handlingCallMe = ref(0)
    const handlingContactAnotherNumber = ref(0)
    const handlingPhone = ref('')
    const handlingUseDoorBill = ref(0)
    const defaultPickupDay = ref(null)
    const defaultPickupTime = ref(null)
    const defaultPickupDayText = ref('')
    const defaultPickupTimeText = ref('')
    const pickupDays = ref([])
    const pickupHours = ref([])
    const deliveryCallMe = ref(0)
    const deliveryContactAnotherNumber = ref(0)
    const deliveryPhone = ref('')
    const deliveryUseDoorBill = ref(0)
    const perfuming = ref(0)
    const ironingType = ref(null)
    const ironingTypes = ref([])
    const starchLevel = ref(null)
    const starchLevels = ref([])
    const foldingHanging = ref(null)
    const laundryBagNextToTheDoor = ref(0)
    const hangerItBehindTheDoor = ref(0)
    const foldingHangingTypes = ref([])
    const loading = ref(false)
    const editDefaultPickupDateTime = ref(false)

    const getUserPreferences = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/user/preferences/${props.customerId}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        handlingCallMe.value = res.data.data.user_preferences.handling_call_me
        handlingContactAnotherNumber.value = res.data.data.user_preferences.handling_call_another_number
        handlingPhone.value = res.data.data.user_preferences.handling_phone
        handlingUseDoorBill.value = res.data.data.user_preferences.handling_use_door_bill
        // defaultPickupDay.value = res.data.data.user_preferences.default_pickup_day ? res.data.data.user_preferences.default_pickup_day.id : null
        // if (pickupDays.value.length > 0) {
        //   pickupHours.value = defaultPickupDay.value ? pickupDays.value.filter(day => day.id === defaultPickupDay.value)[0].hours : []
        //   defaultPickupTime.value = res.data.data.user_preferences.default_pickup_time ? res.data.data.user_preferences.default_pickup_time.id : null
        // }
        defaultPickupDayText.value = res.data.data.user_preferences.default_pickup_day ? res.data.data.user_preferences.default_pickup_day.name : ''
        defaultPickupTimeText.value = res.data.data.user_preferences.default_pickup_time ? res.data.data.user_preferences.default_pickup_time.start_at + ' - ' + res.data.data.user_preferences.default_pickup_time.end_at : ''
        deliveryCallMe.value = res.data.data.user_preferences.delivery_call_me
        deliveryContactAnotherNumber.value = res.data.data.user_preferences.delivery_call_another_number
        deliveryPhone.value = res.data.data.user_preferences.delivery_phone
        deliveryUseDoorBill.value = res.data.data.user_preferences.delivery_use_door_bill
        perfuming.value = res.data.data.user_preferences.perfuming
        ironingType.value = res.data.data.user_preferences.ironing_type ? res.data.data.user_preferences.ironing_type.id : null
        starchLevel.value = res.data.data.user_preferences.starch_quantity ? res.data.data.user_preferences.starch_quantity.id : null
        foldingHanging.value = res.data.data.user_preferences.folding_and_hanging_type ? res.data.data.user_preferences.folding_and_hanging_type.id : null
        laundryBagNextToTheDoor.value = res.data.data.user_preferences.laundry_bag_door
        hangerItBehindTheDoor.value = res.data.data.user_preferences.hanger_behind_the_door
      })
    }

    const getPickupDays = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/days`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        pickupDays.value = res.data.data.days
        getUserPreferences()
      })
    }

    const getIroningTypes = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/ironing-types`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        ironingTypes.value = res.data.data.ironing_types
      })
    }

    const getStarchLevels = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/starch-quantities`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        starchLevels.value = res.data.data.starch_quantities
      })
    }

    const getFoldingAndHangingTypes = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/folding-and-hanging-types`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        foldingHangingTypes.value = res.data.data.folding_and_hanging_types
      })
    }

    const handlePickupDayChange = () => {
      defaultPickupTime.value = null
      pickupHours.value = defaultPickupDay.value ? pickupDays.value.filter(day => day.id === defaultPickupDay.value)[0].hours : []
    }

    const handleChangePreferences = () => {
      loading.value = true

      const data = {
        handling_call_me: handlingCallMe.value,
        handling_call_another_number: handlingContactAnotherNumber.value,
        handling_use_door_bill: handlingUseDoorBill.value,
        delivery_call_me: deliveryCallMe.value,
        delivery_call_another_number: deliveryContactAnotherNumber.value,
        delivery_use_door_bill: deliveryUseDoorBill.value,
        perfuming: perfuming.value,
        ironing_type_id: ironingType.value,
        starch_quantity_id: starchLevel.value,
        folding_and_hanging_type_id: foldingHanging.value,
        laundry_bag_door: laundryBagNextToTheDoor.value,
        hanger_behind_the_door: hangerItBehindTheDoor.value,
        auto_pickup: 0,
      }

      if (editDefaultPickupDateTime.value) {
        data.default_pickup_day_id = defaultPickupDay.value
        data.default_pickup_time_id = defaultPickupTime.value
        data.auto_pickup = 1
      }

      if (handlingContactAnotherNumber.value) {
        if (!handlingPhone.value) {
          Swal.fire({
            title: 'You have to enter handling phone',
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            showConfirmButton: false,
          })
          loading.value = false
          return
        }
        data.handling_phone = handlingPhone.value
      }

      if (deliveryContactAnotherNumber.value) {
        if (!deliveryPhone.value) {
          Swal.fire({
            title: 'You have to enter delivery phone',
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            showConfirmButton: false,
          })
          loading.value = false
          return
        }
        data.delivery_phone = deliveryPhone.value
      }

      axios.put(`${process.env.VUE_APP_BASE_API_URL}/pos/user/preferences/${props.customerId}`, data, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        handlingCallMe.value = res.data.data.user_preferences.handling_call_me
        handlingContactAnotherNumber.value = res.data.data.user_preferences.handling_call_another_number
        handlingPhone.value = res.data.data.user_preferences.handling_phone
        handlingUseDoorBill.value = res.data.data.user_preferences.handling_use_door_bill
        defaultPickupDay.value = res.data.data.user_preferences.default_pickup_day ? res.data.data.user_preferences.default_pickup_day.id : null
        defaultPickupTime.value = res.data.data.user_preferences.default_pickup_time ? res.data.data.user_preferences.default_pickup_time.id : null
        deliveryCallMe.value = res.data.data.user_preferences.delivery_call_me
        deliveryContactAnotherNumber.value = res.data.data.user_preferences.delivery_call_another_number
        deliveryPhone.value = res.data.data.user_preferences.delivery_phone
        deliveryUseDoorBill.value = res.data.data.user_preferences.delivery_use_door_bill
        perfuming.value = res.data.data.user_preferences.perfuming
        ironingType.value = res.data.data.user_preferences.ironing_type ? res.data.data.user_preferences.ironing_type.id : null
        starchLevel.value = res.data.data.user_preferences.starch_quantity ? res.data.data.user_preferences.starch_quantity.id : null
        foldingHanging.value = res.data.data.user_preferences.folding_and_hanging_type ? res.data.data.user_preferences.folding_and_hanging_type.id : null
        laundryBagNextToTheDoor.value = res.data.data.user_preferences.laundry_bag_door
        hangerItBehindTheDoor.value = res.data.data.user_preferences.hanger_behind_the_door
        loading.value = false

        Swal.fire({
          title: 'Success',
          icon: 'success',
          toast: true,
          timer: 2000,
          position: 'top-end',
          showConfirmButton: false,
        })
      }).catch(err => {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          toast: true,
          timer: 2000,
          position: 'top-end',
          showConfirmButton: false,
        })
        loading.value = false
      })
    }

    // eslint-disable-next-line consistent-return
    const numbersOnly = evt => {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    }

    onMounted(() => {
      getPickupDays()
      getIroningTypes()
      getStarchLevels()
      getFoldingAndHangingTypes()
    })

    return {
      handlingCallMe,
      handlingContactAnotherNumber,
      handlingPhone,
      handlingUseDoorBill,
      pickupDays,
      pickupHours,
      defaultPickupDay,
      defaultPickupTime,
      defaultPickupDayText,
      defaultPickupTimeText,
      deliveryCallMe,
      deliveryContactAnotherNumber,
      deliveryPhone,
      deliveryUseDoorBill,
      perfuming,
      ironingType,
      ironingTypes,
      starchLevel,
      starchLevels,
      foldingHanging,
      foldingHangingTypes,
      laundryBagNextToTheDoor,
      hangerItBehindTheDoor,
      loading,
      editDefaultPickupDateTime,
      handlePickupDayChange,
      handleChangePreferences,
      numbersOnly,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
