<template>
  <b-sidebar
    :id="id"
    sidebar-class="sidebar-right"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="title"
    @hidden="hidden"
    @shown="shown"
  >
    <slot />
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: () => '',
    },
    hidden: {
      type: Function,
      default: () => {},
    },
    shown: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
