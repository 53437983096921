<template>
  <div
    class="radio-badge"
  >
    <feather-icon
      icon="CheckIcon"
      size="13"
    />
  </div>
</template>
<script>
export default {}
</script>
