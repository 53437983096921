<template>
  <div>
    <div
      v-if="address.area && address.area.city"
    >
      <b>City: </b><span>{{ address.area.city.name }}</span>
    </div>
    <div
      v-if="address.area"
    >
      <b>Area: </b><span>{{ address.area.name }}</span>
    </div>
    <div
      v-if="address.block"
    >
      <b>Block: </b><span>{{ address.block }}</span>
    </div>
    <div
      v-if="address.avenue"
    >
      <b>Avenue: </b><span>{{ address.avenue }}</span>
    </div>
    <div
      v-if="address.street"
    >
      <b>Street: </b><span>{{ address.street }}</span>
    </div>
    <div
      v-if="address.building"
    >
      <b>Building: </b><span>{{ address.building }}</span>
    </div>
    <div
      v-if="address.floor_number"
    >
      <b>Floor Number: </b><span>{{ address.floor_number }}</span>
    </div>
    <div
      v-if="address.apartment_number"
    >
      <b>Apartment Number: </b><span>{{ address.apartment_number }}</span>
    </div>
    <div
      v-if="address.landmark"
    >
      <b>Landmark: </b><span>{{ address.landmark }}</span>
    </div>
    <div
      v-if="address.details"
    >
      <b>Details: </b><span>{{ address.details }}</span>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  setup() {

  },
}
</script>
