<template>
  <div class="d-flex flex-column align-items-start my-4 mx-4">
    <div class="d-flex flex-column align-items-start w-100">
      <h4 class="">
        Select Subscription
      </h4>
      <v-select
        v-model="subscription"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="subscriptions"
        label="name"
        :clearable="true"
        class="per-page-selector d-inline-block w-100"
        placeholder="Select subscription"
      />
      <div class="my-2 d-flex align-items-center w-100 justify-content-between">
        <div>
          <h5 class="text-black mb-2">
            Total Amount
          </h5>
          <div class="mx-2">
            <h1 class="text-black font-weight-bolder">
              {{ subscription ? subscription.price : '0.000' }}
              <h5 class="text-black d-inline">
                KWD
              </h5>
            </h1>
          </div>
        </div>
        <div>
          <h5 class="text-black mb-2">
            Free Credit
          </h5>
          <div class="mx-2">
            <h1 class="text-black font-weight-bolder">
              {{ subscription ? subscription.balance : '0.000' }}
              <h5 class="text-black d-inline">
                KWD
              </h5>
            </h1>
          </div>
        </div>
      </div>
      <hr class="w-100" />
      <h3 class="text-center w-100">OR</h3>
      <hr class="w-100 mb-3" />
      <div class="d-flex flex-column align-items-start w-100 border-bottom mb-3">
        <h4>
          Add Subscription Balance
        </h4>
        <input
          v-model="amount"
          class="form-control"
          @keypress="numbersOnly"
          type="number"
          step="0.5"
          min="0"
        >
      </div>
    </div>
    <div class="my-5 w-100">
      <h5>Select Payment Method</h5>
      <b-row class="w-100 justify-content-center my-2">
        <b-col
          v-if="!amount && permissions && permissions.pay_cash"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 1
            }"
            @click="() => handleSelectPayment(1)"
          >
            <radio-badge v-if="payment === 1" />
            <img
              src="@/assets/images/icons/money.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">Cash</span>
          </div>
        </b-col>
        <b-col
          v-if="!amount"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 3
            }"
            @click="() => handleSelectPayment(3)"
          >
            <radio-badge v-if="payment === 3" />
            <img
              src="@/assets/images/icons/knet.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">KNET</span>
          </div>
        </b-col>
        <b-col
          v-if="!subscription && permissions && permissions.pay_cash"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 4
            }"
            @click="() => handleSelectPayment(4)"
          >
            <radio-badge v-if="payment === 4" />
            <img
              src="@/assets/images/icons/visa.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">Other</span>
          </div>
        </b-col>
        <b-col
          v-if="!subscription && permissions && permissions.pay_cash"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 5
            }"
            @click="() => handleSelectPayment(5)"
          >
            <radio-badge v-if="payment === 5" />
            <img
              src="@/assets/images/icons/external-visa.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">External Visa</span>
          </div>
        </b-col>
      </b-row>
<!--      <b-row-->
<!--        v-if="payment === 'cash'"-->
<!--      >-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="6"-->
<!--          lg="6"-->
<!--          md="6"-->
<!--          sm="12"-->
<!--        >-->
<!--          <b-form-group-->
<!--            label="RECEIVED"-->
<!--            label-for="received"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="received"-->
<!--              v-model="received"-->
<!--              class="received mb-3"-->
<!--              placeholder="Received"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <div>-->
<!--        <p class="text-success">-->
<!--          Wallet balance will be deducted-->
<!--        </p>-->
<!--      </div>-->
      <b-button
        variant="primary"
        class="w-100"
        :disabled="(!subscription && !amount) || !payment || loading"
        @click="handleBuySubscription"
      >
        {{ loading ? 'Loading...' : 'Buy' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { ref, onUpdated, onMounted, watch, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BButton,
  // BTab,
  // BTabs,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RadioBadge from '../../RadioBadge.vue'

export default {
  components: {
    BButton,
    // BTab,
    // BTabs,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    RadioBadge,
    vSelect,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  emits: ['refreshCustomerDetails'],
  setup(props, context) {
    const toast = useToast()
    const subscription = ref(null)
    const subscriptions = ref([])
    const amount = ref(null)
    const received = ref(0)
    const payment = ref(null)
    const loading = ref(false)

    const permissions = computed(() => store.getters['auth/getPermissions'])

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      getSubscriptions()
    })

    watch(amount, (current, prev) => {
      if (!prev) {
        payment.value = null
      }
      // if (amount.value && payment.value === 3) {
      //   payment.value = 4
      // }
    })

    watch(subscription, (current, prev) => {
      if (!prev) {
        payment.value = null
      }
      // if (amount.value && payment.value === 3) {
      //   payment.value = 4
      // }
    })

    onUpdated(() => {
      // amount.value = props.data.pending_amount
    })

    const getSubscriptions = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/subscriptions`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
        .then(res => {
          subscriptions.value = res.data.data.items
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching Subscriptions' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const handleSelectPayment = selectedPayment => {
      payment.value = selectedPayment
    }

    const numbersOnly = (evt) => {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    const handleBuySubscription = () => {
      loading.value = true
      if (amount.value) {
        const data = {
          payment_method_id: payment.value,
          deduct_from_wallet: false,
        }
        axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/subscriptions/balance/${props.customerId}/${amount.value}`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
          .then(res => {
            loading.value = false

            context.emit('refreshCustomerDetails')
            context.root.$emit('bv::toggle::collapse', 'subscriptions')
            toast({
              component: ToastificationContent,
              props: {
                title: 'Added Successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(err => {
            loading.value = false
            toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
          })
      } else {
        const data = {
          payment_method_id: payment.value,
          deduct_from_wallet: false,
        }
        axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/subscriptions/${subscription.value.id}/user/${props.customerId}`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
          .then(res => {
            loading.value = false
            const messageText = ' عميلنا العزيز يرجى اضافة مبلغ الاشتراك من خلال الرابط التالي - Dear customer please add the subscription amount through the following link '
            if (res.data.data.user_subscription.payment_url) {
              window.open(`https://api.whatsapp.com/send?phone=+965${props.phone}&text=${messageText}${encodeURIComponent(res.data.data.user_subscription.payment_url)}`, '_blank')
              // window.open(`https://web.whatsapp.com/send?phone=+965${props.phone}&text=${encodeURIComponent(res.data.data.user_subscription.payment_url)}`, '_blank')
            }
            context.emit('refreshCustomerDetails')
            context.root.$emit('bv::toggle::collapse', 'subscriptions')
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(err => {
            loading.value = false
            toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
          })
      }
    }

    return {
      subscription,
      subscriptions,
      amount,
      received,
      payment,
      permissions,
      loading,
      handleSelectPayment,
      handleBuySubscription,
      numbersOnly,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
