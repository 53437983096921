import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomerOrders() {
  // Use toast
  const toast = useToast()

  const refCustomerAddressesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '#', sortable: true },
    { key: 'id', sortable: false },
    { key: 'name', sortable: false },
    { key: 'area', sortable: false },
    { key: 'phone', sortable: false },
    { key: 'street', sortable: false },
    { key: 'block', sortable: false },
    { key: 'building', sortable: false },
    { key: 'floor_number', sortable: false },
    { key: 'apartment_number', sortable: false },
    { key: 'landmark', sortable: false },
    { key: 'avenue', sortable: false },
    { key: 'created_at', sortable: false },
  ]
  const id = ref('')
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    refCustomerAddressesTable.value.refresh()
  }

  watch([searchQuery, id], () => {
    refetchData()
  })

  const fetchCustomerAddresses = (ctx, callback) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API_URL}/pos/user/${id.value}/addresses`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const customerAddresses = res.data.data.addresses

        callback(customerAddresses)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Customer Addresses' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCustomerAddresses,
    tableColumns,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerAddressesTable,
    id,
    refetchData,
  }
}
