<template>
  <div class="d-flex flex-column align-items-start my-4 mx-4">
    <div class="d-flex flex-column align-items-start w-100 border-bottom mb-3">
      <h4>
        Add Wallet Balance
      </h4>
      <input
        v-model="amount"
        class="form-control"
        @keypress="numbersOnly"
        type="number"
        step="0.5"
        min="0"
      >
<!--      <div class="my-2 d-flex align-items-center w-100 justify-content-between">-->
<!--        <div>-->
<!--          <h5 class="text-black mb-2">-->
<!--            Package Pirce-->
<!--          </h5>-->
<!--          <div class="d-flex justify-content-around align-items-center">-->
<!--            <img src="@/assets/images/icons/tag.png">-->
<!--            <div class="mx-2">-->
<!--              <h1 class="text-black font-weight-bolder">-->
<!--                0.00-->
<!--                <h5 class="text-black d-inline">-->
<!--                  KWD-->
<!--                </h5>-->
<!--              </h1>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <h5 class="text-black mb-2">-->
<!--            Points you'll get-->
<!--          </h5>-->
<!--          <div class="d-flex justify-content-around align-items-center">-->
<!--            <img src="@/assets/images/icons/profit.png">-->
<!--            <div class="mx-2">-->
<!--              <h1 class="text-black font-weight-bolder">-->
<!--                0-->
<!--                <h5 class="text-black d-inline">-->
<!--                  Points-->
<!--                </h5>-->
<!--              </h1>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
<!--    <div class="d-flex flex-column align-items-start w-100">-->
<!--      <h4 class="text-black">-->
<!--        Payment Details-->
<!--      </h4>-->
<!--      <div class="my-2 d-flex align-items-center w-100 justify-content-start">-->
<!--        <div>-->
<!--          <h5 class="text-black mb-2">-->
<!--            Total amount-->
<!--          </h5>-->
<!--          <div class="mx-2">-->
<!--            <h1 class="text-black font-weight-bolder">-->
<!--              0.00-->
<!--              <h5 class="text-black d-inline">-->
<!--                KWD-->
<!--              </h5>-->
<!--            </h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="my-5 w-100">
      <h5>Select Payment Method</h5>
      <b-row class="w-100 justify-content-center my-2">
        <b-col
          v-if="permissions && permissions.pay_cash"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 1
            }"
            @click="() => handleSelectPayment(1)"
          >
            <radio-badge v-if="payment === 1" />
            <img
              src="@/assets/images/icons/money.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">Cash</span>
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 3
            }"
            @click="() => handleSelectPayment(3)"
          >
            <radio-badge v-if="payment === 3" />
            <img
              src="@/assets/images/icons/knet.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">KNET</span>
          </div>
        </b-col>
        <b-col
          v-if="permissions && permissions.pay_cash"
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
        >
          <div
            :class="{
              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
              'radio-selected': payment === 4
            }"
            @click="() => handleSelectPayment(4)"
          >
            <radio-badge v-if="payment === 4" />
            <img
              src="@/assets/images/icons/visa.png"
              width="32"
              height="32"
              class="mt-1"
            >
            <span class="my-1">Other</span>
          </div>
        </b-col>
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="4"-->
<!--          lg="4"-->
<!--          md="4"-->
<!--          sm="12"-->
<!--        >-->
<!--          <div-->
<!--            :class="{-->
<!--              'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,-->
<!--              'radio-selected': payment === 'credit_card'-->
<!--            }"-->
<!--            @click="() => handleSelectPayment('credit_card')"-->
<!--          >-->
<!--            <radio-badge v-if="payment === 'credit_card'" />-->
<!--            <img-->
<!--              src="@/assets/images/icons/visa.png"-->
<!--              width="32"-->
<!--              height="32"-->
<!--              class="mt-1"-->
<!--            >-->
<!--            <span class="my-1">Credit Card</span>-->
<!--          </div>-->
<!--        </b-col>-->
      </b-row>
<!--      <b-row-->
<!--        v-if="payment === 1"-->
<!--      >-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="6"-->
<!--          lg="6"-->
<!--          md="6"-->
<!--          sm="12"-->
<!--        >-->
<!--          <b-form-group-->
<!--            label="RECEIVED"-->
<!--            label-for="received"-->
<!--          >-->
<!--            <b-form-input-->
<!--              id="received"-->
<!--              v-model="received"-->
<!--              class="received mb-3"-->
<!--              placeholder="Received"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <b-button
        variant="primary"
        class="w-100"
        :disabled="!amount || !payment || loading"
        @click="handleAddBalance"
      >
        {{ loading ? 'Loading...' : 'Add Balance' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { ref, onUpdated, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BButton,
  // BTab,
  // BTabs,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RadioBadge from '../../RadioBadge.vue'

export default {
  components: {
    BButton,
    // BTab,
    // BTabs,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    RadioBadge,
    vSelect,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  emits: ['refreshCustomerDetails'],
  setup(props, context) {
    const toast = useToast()
    const amount = ref(null)
    const payment = ref(null)
    const loading = ref(false)

    const permissions = computed(() => store.getters['auth/getPermissions'])

    onUpdated(() => {
      // amount.value = props.data.pending_amount
    })

    const handleSelectPayment = selectedPayment => {
      payment.value = selectedPayment
    }

    const numbersOnly = evt => {
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }

    const handleAddBalance = () => {
      if (amount.value <= 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Amount must be greater than 0',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        loading.value = true

        const data = {
          payment_method_id: payment.value,
          amount: amount.value,
        }
        axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/wallet/${props.customerId}`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
          .then(res => {
            loading.value = false
            const messageText = ' عميلنا العزيز يرجى اضافة رصيد المحفظة من خلال الرابط التالي - Dear customer please add your wallet balance through the following link '
            if (res.data.data.payment_url) {
              window.open(`https://api.whatsapp.com/send?phone=+965${props.phone}&text=${messageText}${encodeURIComponent(res.data.data.payment_url)}`, '_blank')
            }
            context.emit('refreshCustomerDetails')
            context.root.$emit('bv::toggle::collapse', 'wallet-balance')
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(err => {
            loading.value = false
            toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
          })
      }
    }

    return {
      amount,
      payment,
      permissions,
      loading,
      handleSelectPayment,
      handleAddBalance,
      numbersOnly,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
