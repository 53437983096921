<template>
  <div class="container my-2">
    <validation-observer ref="addressRules">
      <b-form>
        <b-row class="container my-2">
          <h3>Address</h3>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="address-name"
              >
                Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Address Name"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="address-name"
                    v-model="addressName"
                    placeholder="Name"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="phone"
              >
                Phone Number <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    placeholder="Phone Number"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="paci"
              >
                PACI Number
              </label>
              <b-input-group>
                <b-form-input
                  id="paci"
                  v-model="paci"
                  placeholder="PACI Number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="city"
              >
                City <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <v-select
                  id="city"
                  v-model="city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cities"
                  label="name"
                  :clearable="false"
                  class="w-100"
                  placeholder="Select City"
                  :reduce="ci => ci.id"
                  :state="errors.length > 0 ? false:null"
                  @input="handleCityChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="area"
              >
                Area <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Area"
                rules="required"
              >
                <v-select
                  id="area"
                  v-model="area"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areas"
                  label="name"
                  :clearable="false"
                  class="w-100"
                  placeholder="Select Area"
                  :reduce="ar => ar.id"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="block"
              >
                Block <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Block"
                rules="required"
              >
                <b-form-input
                  id="block"
                  v-model="block"
                  placeholder="Block"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="avenue"
              >
                Avenue
              </label>
              <b-form-input
                id="avenue"
                v-model="avenue"
                placeholder="Avenue"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="street"
              >
                Street <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Street"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="street"
                    v-model="street"
                    placeholder="Street"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="building"
              >
                Building/House <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Building/House"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="building"
                    v-model="building"
                    placeholder="Building/House"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="floor"
              >
                Floor
              </label>
              <b-form-input
                id="floor"
                v-model="floor"
                placeholder="Floor"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="apartment"
              >
                Apartment
              </label>
              <b-form-input
                id="apartment"
                v-model="apartment"
                placeholder="Apartment"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="landmark"
              >
                Landmark
              </label>
              <b-form-input
                id="landmark"
                v-model="landmark"
                placeholder="Landmark"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="details"
              >
                Details
              </label>
              <b-form-input
                id="details"
                v-model="details"
                placeholder="Details"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="primary"
              >
                Primary
              </label>
              <b-form-checkbox
                id="primary"
                v-model="primary"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="container">
          <b-col md="12">
            <div class="text-danger">
              {{ error }}
            </div>
          </b-col>
          <!-- submit -->
          <b-col md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 w-100 my-2"
              :disabled="submitButtonDisabled"
              @click.prevent="handleSubmitAddress"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  between,
} from '@validations'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    customerId: {
      type: [Number, String],
      required: true,
    },
    address: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['refreshAddressesList'],
  setup(props, context) {
    const toast = useToast()
    const addressRules = ref(null)
    const cities = ref([])
    const areas = ref([])

    const addressName = ref('')
    const phone = ref('')
    const city = ref('')
    const area = ref('')
    const block = ref('')
    const avenue = ref('')
    const street = ref('')
    const building = ref('')
    const floor = ref('')
    const apartment = ref('')
    const landmark = ref('')
    const details = ref('')
    const primary = ref(false)
    const paci = ref('')
    const error = ref('')
    const submitButtonDisabled = ref(false)

    const getCities = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/countries/1/cities`).then(res => {
        cities.value = res.data.data.items
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cities list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const handleCityChange = () => {
      area.value = ''
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/countries/1/cities/${city.value}`).then(res => {
        areas.value = res.data.data.items
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching areas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    onMounted(() => {
      getCities()
      if (props.type === 'edit') {
        city.value = props.address.area.city.id
        handleCityChange()
        addressName.value = props.address.name
        phone.value = props.address.phone
        area.value = props.address.area.id
        block.value = props.address.block
        avenue.value = props.address.avenue
        street.value = props.address.street
        building.value = props.address.building
        floor.value = props.address.floor_number
        apartment.value = props.address.apartment_number
        landmark.value = props.address.landmark
        details.value = props.address.details
        primary.value = !!props.address.primary
        paci.value = props.address.paci_no
      }
    })

    const handleSubmitAddress = () => {
      addressRules.value.validate().then(success => {
        if (success) {
          submitButtonDisabled.value = true

          const data = {
            country_id: 1,
            name: addressName.value,
            phone: phone.value,
            area_id: area.value,
            avenue: avenue.value,
            apartment_number: apartment.value,
            floor_number: floor.value,
            landmark: landmark.value,
            primary: primary.value,
            block: block.value,
            street: street.value,
            building: building.value,
            details: details.value,
            paci_no: paci.value,
          }

          let url = `${process.env.VUE_APP_BASE_API_URL}/pos/user/${props.customerId}/addresses`
          if (props.type === 'edit') {
            url = `${process.env.VUE_APP_BASE_API_URL}/pos/user/${props.customerId}/addresses/${props.address.id}`
          }

          axios.post(url, data, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }).then(res => {
            submitButtonDisabled.value = false

            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
            context.emit('refreshAddressesList')

            if (props.type === 'edit') {
              context.root.$emit('bv::toggle::collapse', 'address-form')
            } else {
              context.root.$emit('bv::toggle::collapse', 'address-add-form')
            }
          }).catch(err => {
            error.value = err.response.data.message
            submitButtonDisabled.value = false
          })
        }
      })
    }

    return {
      addressRules,
      areas,
      cities,
      primary,
      addressName,
      phone,
      area,
      block,
      street,
      building,
      floor,
      apartment,
      city,
      avenue,
      landmark,
      details,
      paci,
      handleSubmitAddress,
      required,
      between,
      handleCityChange,
      submitButtonDisabled,
      error,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
