<template>
  <b-modal
    id="order-note-modal"
    ref="modalRef"
    centered
    hide-footer
    size="md"
    title="Send Note To The Client"
    @shown="handleModalOpen"
    @hidden="handleModalClose"
  >
    <b-row>
      <b-col cols="12">
        <label
          for="images"
          class="mt-1"
        >
          Images <span class="text-danger">*</span>
        </label>
        <b-form-file
          id="images"
          accept=".jpg, .png, .gif, .jpeg"
          multiple
          @change="handleUploadImages"
        />
      </b-col>
      <b-col cols="12">
        <label
          for="note"
          class="mt-1"
        >
          Note <span class="text-danger">*</span>
        </label>
        <b-form-textarea
          id="note"
          v-model="note"
          placeholder="Add Note"
          rows="4"
          no-resize
        />
      </b-col>
      <b-col
        cols="12"
        class="text-right"
      >
        <span class="text-danger">{{ error }}</span>
      </b-col>
      <b-col
        cols="12"
        class="text-right"
      >
        <button
          class="btn btn-primary btn-sm my-1"
          :disabled="submitButtonDisabled"
          @click="handleAddNote"
        >
          <span v-if="!submitButtonDisabled">Send</span>
          <div
            v-else
            class="spinner-border"
            role="status"
            style="width: 15px; height: 15px"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormTextarea,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    updateOrderData: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const images = ref([])
    const note = ref('')
    const error = ref('')
    const submitButtonDisabled = ref(false)
    const modalRef = ref(null)

    const handleModalOpen = () => {

    }

    const handleModalClose = () => {
      images.value = []
      note.value = ''
    }

    const handleUploadImages = e => {
      images.value = e.target.files
    }

    const handleAddNote = () => {
      submitButtonDisabled.value = true

      const formData = new FormData()

      images.value.forEach(image => {
        formData.append('image[]', image)
      })
      formData.append('laundry_note', note.value)
      axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/laundry-orders/${props.orderId}/add-note`, formData, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        submitButtonDisabled.value = false
        if (res.data.code === 202) {
          props.updateOrderData(res.data.data.order)
          modalRef.value.hide()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        }
      }).catch(err => {
        error.value = err.response.data.message
        submitButtonDisabled.value = false
      })
    }

    return {
      images,
      note,
      error,
      submitButtonDisabled,
      modalRef,
      handleModalOpen,
      handleModalClose,
      handleAddNote,
      handleUploadImages,
    }
  },
}
</script>
